import { motion } from 'framer-motion';
import { useRef } from 'react';
import { Row } from 'react-bootstrap';
import HTMLFlipBook from 'react-pageflip';
import { useStyles } from 'styles';
import { FlipbookProps } from 'types';

export const Flipbook: React.FC<FlipbookProps> = ({
  width,
  height,
  images,
}) => {
  const flipBook = useRef();
  const classes = useStyles();

  return (
    <div style={{ marginTop: '12%' }}>
      {/* @ts-ignore */}
      <HTMLFlipBook width={width} height={height} ref={flipBook}>
        {images.map((image, id) => (
          <div key={id}>
            <img
              src={image}
              alt={`${id}`}
              style={{ width: width, height: height }}
            />
          </div>
        ))}
      </HTMLFlipBook>
      <Row className="d-flex justify-content-center">
        {images.map((image, id) => {
          //We keep only odd numbers dots as, in terms of navigation, there's no need for the even ones too
          if (id % 2 === 0) {
            return null;
          }
          return (
            <motion.div
              whileTap={{ scale: 0.8 }}
              key={image}
              onClick={() => {
                //@ts-ignore
                flipBook.current.pageFlip().turnToPage(id);
              }}
              className={classes.flipbookButtons}
            >
              {id}
            </motion.div>
          );
        })}
      </Row>
    </div>
  );
};
