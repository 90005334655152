import { HomeButton } from 'components/HomeButton';
import { PlansButtonsContainer } from 'components/PlansButtonsContainer';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStyles } from 'styles';
import { availabilityDataType, BasicPageProps, PlanDataType } from 'types';
import { GestureWindow } from 'utils/GestureWindow';

const PLANS_DATA = [
  {
    buttonText: 'Piano -1',
    backgroundImage: '/image/keyplan/keyplan-interrato.jpg',
    markers: [],
  },
  {
    buttonText: 'Piano Terra',
    backgroundImage: '/image/keyplan/keyplan-terra.jpg',
    markers: [
      { title: 'V01', top: '41%', left: '66%' },
      { title: 'TO1', top: '31%', left: '74%' },
      { title: 'TO2', top: '26%', left: '74%' },
      { title: 'TO3', top: '21%', left: '74%' },
      { title: 'TO4', top: '6%', left: '74%' },
    ],
  },
  {
    buttonText: 'Piano 1',
    backgroundImage: '/image/keyplan/keyplan-primo.jpg',
    markers: [
      { title: 'D12', top: '48%', left: '41%' },
      { title: 'D13', top: '51%', left: '37%' },
      { title: 'D11', top: '57%', left: '38%' },
      { title: 'C14', top: '64%', left: '38%' },
      { title: 'C11', top: '64%', left: '44%' },
      { title: 'C12', top: '71%', left: '44%' },
      { title: 'B13', top: '71%', left: '50%' },
      { title: 'B12', top: '71%', left: '54%' },
      { title: 'B11', top: '64%', left: '55%' },
      { title: 'B14', top: '66%', left: '48%' },
      { title: 'A12', top: '71%', left: '63%' },
      { title: 'A11', top: '67%', left: '67%' },
      { title: 'V01', top: '41%', left: '66%' },
      { title: 'T01', top: '31%', left: '73%' },
      { title: 'T02', top: '26%', left: '73%' },
      { title: 'T03', top: '21%', left: '73%' },
      { title: 'T11', top: '15%', left: '74%' },
      { title: 'T04', top: '6%', left: '77%' },
    ],
  },
  {
    buttonText: 'Piano 2',
    backgroundImage: '/image/keyplan/keyplan-secondo.jpg',
    markers: [
      { title: 'D22', top: '48%', left: '41%' },
      { title: 'D21', top: '57%', left: '38%' },
      { title: 'C21', top: '64%', left: '44%' },
      { title: 'C22', top: '72%', left: '44%' },
      { title: 'C23', top: '72%', left: '39%' },
      { title: 'B24', top: '65%', left: '48%' },
      { title: 'B21', top: '65%', left: '55%' },
      { title: 'B22', top: '71%', left: '54%' },
      { title: 'B23', top: '71%', left: '50%' },
      { title: 'A24', top: '66%', left: '59%' },
      { title: 'A23', top: '72%', left: '62%' },
      { title: 'A22', top: '72%', left: '66%' },
      { title: 'A21', top: '66%', left: '66%' },
      { title: 'T23', top: '6%', left: '77%' },
      { title: 'T22', top: '17%', left: '75%' },
      { title: 'T21', top: '25%', left: '77%' },
    ],
  },
  {
    buttonText: 'Piano 3',
    backgroundImage: '/image/keyplan/keyplan-terzo.jpg',
    markers: [
      { title: 'D32', top: '48%', left: '41%' },
      { title: 'D31', top: '57%', left: '38%' },
      { title: 'C31', top: '64%', left: '44%' },
      { title: 'C32', top: '72%', left: '44%' },
      { title: 'C33', top: '72%', left: '39%' },
      { title: 'B34', top: '65%', left: '48%' },
      { title: 'B31', top: '65%', left: '55%' },
      { title: 'B32', top: '71%', left: '54%' },
      { title: 'B33', top: '71%', left: '50%' },
      { title: 'A33', top: '66%', left: '59%' },
      { title: 'A32', top: '72%', left: '62%' },
      { title: 'A31', top: '72%', left: '66%' },
      { title: 'T32', top: '6%', left: '77%' },
      { title: 'T31', top: '25%', left: '77%' },
    ],
  },
  {
    buttonText: 'Piano 4',
    backgroundImage: '/image/keyplan/keyplan-quarto.jpg',
    markers: [
      { title: 'D42', top: '48%', left: '39%' },
      { title: 'D41', top: '57%', left: '38%' },
      { title: 'C41', top: '64%', left: '44%' },
      { title: 'C42', top: '72%', left: '44%' },
      { title: 'C43', top: '72%', left: '39%' },
      { title: 'B43', top: '65%', left: '48%' },
      { title: 'B41', top: '65%', left: '55%' },
      { title: 'B42', top: '71%', left: '50%' },
      { title: 'A43', top: '66%', left: '59%' },
      { title: 'A42', top: '72%', left: '62%' },
      { title: 'A41', top: '72%', left: '66%' },
    ],
  },
  {
    buttonText: 'Piano 5',
    backgroundImage: '/image/keyplan/keyplan-quinto.jpg',
    markers: [
      { title: 'C51', top: '64%', left: '44%' },
      { title: 'C52', top: '72%', left: '39%' },
      { title: 'B53', top: '65%', left: '48%' },
      { title: 'B51', top: '65%', left: '55%' },
      { title: 'B52', top: '71%', left: '50%' },
      { title: 'A53', top: '66%', left: '59%' },
      { title: 'A52', top: '72%', left: '62%' },
      { title: 'A51', top: '72%', left: '66%' },
    ],
  },
  {
    buttonText: 'Piano 6',
    backgroundImage: '/image/keyplan/keyplan-sesto.jpg',
    markers: [
      { title: 'C61', top: '72%', left: '39%' },
      { title: 'B62', top: '65%', left: '48%' },
      { title: 'B61', top: '65%', left: '55%' },
      { title: 'A61', top: '72%', left: '66%' },
    ],
  },
].reverse();

const WINDOW_OFFSET = 50;
const WINDOW_OFFSET_MULTIPLIER = 150;
const JSON_URL =
  'https://digital-tech.s3.eu-south-1.amazonaws.com/initiatives/staging/balilla_living.json?timestamp=';

export const KeyplanPage: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  const classes = useStyles();

  const [availabilityData, setAvailabilityData] =
    useState<availabilityDataType>();

  const [currentImage, setCurrentImage] = useState(backgroundImage);
  const [zoomedMenu, setZoomedMenu] = useState(true);

  const [currentMarkers, setCurrentMarkers] = useState<
    | {
        title: string;
        top: string;
        left: string;
        availability: boolean | undefined;
      }[]
    | []
  >([]);

  const [gestureWindows, setGestureWindows] = useState<string[] | []>([]);

  const fetchAvailabilityData = useCallback(async () => {
    const response = await fetch(
      `${JSON_URL}${Math.floor(Math.random() * 1000)}`,
    );
    const data = await response.json();
    setAvailabilityData(data.flatMap((plan: PlanDataType) => plan.units));
  }, []);

  useEffect(() => {
    fetchAvailabilityData();
  }, [fetchAvailabilityData]);

  const checkAvailability = useCallback(
    (unit: string) => {
      if (!availabilityData) {
        return;
      }
      return availabilityData.find((u) => u.code === unit)?.available;
    },
    [availabilityData],
  );
  const dataWithAvailabilities = useMemo(() => {
    return PLANS_DATA.map((pD) => {
      return {
        ...pD,
        markers: pD.markers.map((m) => {
          return { ...m, availability: checkAvailability(m.title) };
        }),
      };
    });
  }, [checkAvailability]);

  return (
    <LayoutGroup>
      <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col
              md="12"
              className="d-flex align-items-center justify-content-center p-0"
            >
              <AnimatePresence>
                {currentMarkers.map((cM, idx) => (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={classes.planMarker}
                    style={{
                      top: cM.top,
                      left: cM.left,
                      borderBottom: `3px solid ${
                        cM.availability === undefined
                          ? 'grey'
                          : cM.availability
                          ? 'green'
                          : 'red'
                      }`,
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    transition={{
                      duration: 0.8,
                      delay: 1 + idx / 10,
                    }}
                    key={cM.title}
                    onClick={() => {
                      setGestureWindows((prev) => {
                        return [
                          ...prev.filter((gW) => gW !== cM.title),
                          cM.title,
                        ];
                      });
                    }}
                  >
                    {cM.title}
                  </motion.div>
                ))}
              </AnimatePresence>
            </Col>
          </Row>
        </Container>

        {gestureWindows.map((gW, idx) => (
          <GestureWindow
            image={gW}
            key={gW}
            left={idx * WINDOW_OFFSET_MULTIPLIER + WINDOW_OFFSET}
            setGestureWindows={setGestureWindows}
            plansData={PLANS_DATA}
            idx={idx}
          />
        ))}

        <HomeButton />

        <PlansButtonsContainer
          setCurrentImage={setCurrentImage}
          setCurrentMarkers={setCurrentMarkers}
          dataWithAvailabilities={dataWithAvailabilities}
          zoomedMenu={zoomedMenu}
          setZoomedMenu={setZoomedMenu}
        />

        <AnimatePresence>
          <div className={classes.background}>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 2,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              src={currentImage}
              alt={currentImage}
              className={classes.backgroundObject}
              key={currentImage}
            />
          </div>
        </AnimatePresence>
      </div>
    </LayoutGroup>
  );
};
