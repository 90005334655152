import { motion } from 'framer-motion';
import { useStyles } from 'styles';
import { PlanButtonPropsType } from 'types';

export const PlanButton: React.FC<PlanButtonPropsType> = ({
  buttonText,
  delay,
  linkedImage,
  setCurrentImage,
  setCurrentMarkers,
  markers,
  setZoomedMenu,
}) => {
  const classes = useStyles();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 2,
        delay: delay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      onClick={() => {
        setCurrentImage(linkedImage);
        setCurrentMarkers(markers!);
        setZoomedMenu(false);
      }}
    >
      <motion.div whileTap={{ scale: 0.95 }}>
        <div
          className={classes.planButton}
          onClick={() => {
            setCurrentImage(linkedImage);
            setCurrentMarkers(markers!);
          }}
        >
          {buttonText}
        </div>
      </motion.div>
    </motion.div>
  );
};
