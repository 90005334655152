import { MainNavigation } from 'components/MainNavigation';
import { motion, AnimatePresence } from 'framer-motion';
import React, { Fragment } from 'react';
import { BasicPageProps } from 'types';

const VIDEO_DATA = {
  poster: '/video/video.jpg',
  webm: {
    src: '/video/video.webm',
    type: 'video/webm',
  },
  mp4: {
    src: '/video/video.mp4',
    type: 'video/mp4',
  },
};

export const HomePage: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  return (
    <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
      <AnimatePresence>
        <Fragment>
          <motion.img
            src={backgroundImage}
            alt="Home"
            className="img-fluid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="home"
            transition={{
              duration: 1.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          />
          <MainNavigation />
        </Fragment>
      </AnimatePresence>
    </div>
  );
};
