import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  zIndex0: { zIndex: '0' },
  zIndex10: { zIndex: '10' },
  zIndex20: { zIndex: '20' },
  hit: {
    position: 'absolute',
    zIndex: '30',
    height: '40px',
    width: '60px',
    border: 'solid 4px red',
  },
  hitSuccess: {
    borderColor: 'green',
  },
  hit01: {
    top: '720px',
    left: '2818px',
  },
  hit02: {
    top: '624px',
    left: '2818px',
  },
  logoTop: {
    left: '5.5%',
    top: '13%',
    width: '40rem',
    zIndex: '0',
  },
  appContainer: { color: 'red' },
  navLink: { color: 'blue' },
  nav: { flexWrap: 'nowrap' },
  background: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '-10',
    backgroundColor: '#334a66',
  },
  backgroundObject: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  footer: { zIndex: '100' },
  homeButton: {
    position: 'absolute',
    zIndex: 30,
    cursor: 'pointer',
    userSelect: 'none',
  },
  homeIconImg: { width: '50px' },
  mainNavButton: {
    position: 'relative',
    margin: '0 10px',
    cursor: 'pointer',
    userSelect: 'none',
  },
  mainNavButtonInner: {
    color: '#fff',
    margin: 'auto',
    padding: '0 30px 10px 30px',
    borderBottom: '3px solid #c7bc96',
  },
  mainNavButtonImg: { display: 'block', margin: 'auto' },
  planButton: {
    color: '#d6c199',
    textTransform: 'uppercase',
    paddingTop: '1%',
    width: '350px',
    borderBottom: '1px solid #d6c199',
    fontSize: '12px',
    userSelect: 'none',
    cursor: 'pointer',
  },

  buttonContainer: {
    position: 'absolute',
    top: '39%',
    left: '100px',
    zIndex: '30',
  },

  planMarker: {
    position: 'absolute',
    backgroundColor: '#fff',
    opacity: 1,
    color: '#000',
    fontSize: '10px',
    padding: '0 5px',
  },
  galleryContainer: { marginTop: '2%' },
  flipbookButtons: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    backgroundColor: 'rgba(0, 0, 0, .2)',
    margin: 5,
    paddingTop: 10,
    color: '#cfc49d',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
});
