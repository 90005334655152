import { Flipbook } from 'components/Flipbook';
import { HomeButton } from 'components/HomeButton';
import React, { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useStyles } from 'styles';
import { BasicPageProps } from 'types';
import { MotionDiv } from 'utils/MotionDiv';

const BOOK_IMAGES = [
  '/image/materials/materiali-1.jpg',
  '/image/materials/materiali-2.jpg',
  '/image/materials/materiali-3.jpg',
  '/image/materials/materiali-4.jpg',
  '/image/materials/materiali-5.jpg',
  '/image/materials/materiali-6.jpg',
  '/image/materials/materiali-7.jpg',
  '/image/materials/materiali-8.jpg',
  '/image/materials/materiali-9.jpg',
  '/image/materials/materiali-10.jpg',
  '/image/materials/materiali-11.jpg',
  '/image/materials/materiali-12.jpg',
  '/image/materials/materiali-13.jpg',
  '/image/materials/materiali-14.jpg',
  '/image/materials/materiali-15.jpg',
  '/image/materials/materiali-16.jpg',
  '/image/materials/materiali-17.jpg',
  '/image/materials/materiali-18.jpg',
  '/image/materials/materiali-19.jpg',
  '/image/materials/materiali-20.jpg',
  '/image/materials/materiali-21.jpg',
  '/image/materials/materiali-22.jpg',
  '/image/materials/materiali-23.jpg',
  '/image/materials/materiali-24.jpg',
  '/image/materials/materiali-25.jpg',
  '/image/materials/materiali-26.jpg',
  '/image/materials/materiali-27.jpg',
  '/image/materials/materiali-28.jpg',
  '/image/materials/materiali-29.jpg',
  '/image/materials/materiali-30.jpg',
  '/image/materials/materiali-31.jpg',
  '/image/materials/materiali-32.jpg',
  '/image/materials/materiali-33.jpg',
  '/image/materials/materiali-34.jpg',
  '/image/materials/materiali-35.jpg',
  '/image/materials/materiali-36.jpg',
  '/image/materials/materiali-37.jpg',
  '/image/materials/materiali-38.jpg',
  '/image/materials/materiali-39.jpg',
  '/image/materials/materiali-40.jpg',
  '/image/materials/materiali-41.jpg',
  '/image/materials/materiali-42.jpg',
  '/image/materials/materiali-43.jpg',
  '/image/materials/materiali-44.jpg',
  '/image/materials/materiali-45.jpg',
  '/image/materials/materiali-46.jpg',
  '/image/materials/materiali-47.jpg',
  '/image/materials/materiali-48.jpg',
  '/image/materials/materiali-49.jpg',
  '/image/materials/materiali-50.jpg',
  '/image/materials/materiali-51.jpg',
  '/image/materials/materiali-52.jpg',
  '/image/materials/materiali-53.jpg',
  '/image/materials/materiali-54.jpg',
  '/image/materials/materiali-55.jpg',
  '/image/materials/materiali-56.jpg',
  '/image/materials/materiali-57.jpg',
  '/image/materials/materiali-58.jpg',
  '/image/materials/materiali-59.jpg',
  '/image/materials/materiali-60.jpg',
  '/image/materials/materiali-61.jpg',
  '/image/materials/materiali-62.jpg',
  '/image/materials/materiali-63.jpg',
  '/image/materials/materiali-64.jpg',
];
const DEFAULT_WIDTH = 830;
const DEFAULT_HEIGHT = 520;

export const Materials: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Flipbook
              width={DEFAULT_WIDTH}
              height={DEFAULT_HEIGHT}
              images={BOOK_IMAGES}
            />
          </Row>
        </Container>

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Materials"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
