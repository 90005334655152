import { AnimatePresence } from 'framer-motion';
import { Book } from 'pages/Book';
import { HomePage } from 'pages/HomePage';
import { IdlePage } from 'pages/IdlePage';
import { KeyplanPage } from 'pages/KeyplanPage';
import { Location } from 'pages/Location';
import { Map } from 'pages/Map';
import { Materials } from 'pages/Materials';
import { Partner } from 'pages/Partner';
import { StylePage } from 'pages/StylePage';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const BACKGROUND_IMAGES = {
  location: '/image/backgrounds/background-01.png',
  book: '/image/backgrounds/background-book.png',
  materials: '/image/backgrounds/background-materials.png',
  keyplanPage: '/image/keyplan/keyplan-background.jpg',
  style: '/image/backgrounds/background-style.png',
  map: '/image/backgrounds/background-map.png',
  partner: '/image/backgrounds/background-partner.png',
  home: '/image/backgrounds/static-background.png',
};

export const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleOnIdle = () => navigate('/idle-page');
  const timeout = 600000;

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/location"
          element={<Location backgroundImage={BACKGROUND_IMAGES.location} />}
        />
        <Route
          path="/book"
          element={<Book backgroundImage={BACKGROUND_IMAGES.book} />}
        />
        <Route
          path="/materials"
          element={<Materials backgroundImage={BACKGROUND_IMAGES.materials} />}
        />
        <Route
          path="/keyplan-page"
          element={
            <KeyplanPage backgroundImage={BACKGROUND_IMAGES.keyplanPage} />
          }
        />
        <Route
          path="/style"
          element={<StylePage backgroundImage={BACKGROUND_IMAGES.style} />}
        />
        <Route
          path="/map"
          element={<Map backgroundImage={BACKGROUND_IMAGES.map} />}
        />
        <Route
          path="/partner"
          element={<Partner backgroundImage={BACKGROUND_IMAGES.partner} />}
        />
        <Route
          path="/"
          element={<HomePage backgroundImage={BACKGROUND_IMAGES.home} />}
        />
        <Route path="/idle-page" element={<IdlePage />} />
      </Routes>
    </AnimatePresence>
  );
};
