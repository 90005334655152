import 'bootstrap.min.css';
import 'fontawesome.all.min.css';
import 'index.css';
import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';

export const App = () => {
  useEffect(() => {
    const handleContextmenu = (e: Event) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);
  return (
    <HashRouter>
      <AppRoutes />
    </HashRouter>
  );
};
