import { motion } from 'framer-motion';
import React from 'react';

type MotionDivProps = {
  children: JSX.Element;
};

export const MotionDiv: React.FC<MotionDivProps> = ({ children }) => {
  return (
    <motion.div
      className="cover-container d-flex w-100 h-100 mx-auto flex-column"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};
