import { PlanButton } from 'components/PlanButton';
import { motion } from 'framer-motion';
import { useStyles } from 'styles';
import { PlansButtonsContainerProps } from 'types';

export const PlansButtonsContainer: React.FC<PlansButtonsContainerProps> = ({
  setCurrentImage,
  setCurrentMarkers,
  dataWithAvailabilities,
  zoomedMenu,
  setZoomedMenu,
}) => {
  const classes = useStyles();

  return (
    <motion.div
      initial={{ top: 100, opacity: 0 }}
      animate={{ top: 0, opacity: 1 }}
      transition={{
        duration: 2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <div
        className={classes.buttonContainer}
        style={{ width: `${zoomedMenu ? '100%' : '30%'} ` }}
      >
        {dataWithAvailabilities.map((pB, idx) => (
          <PlanButton
            buttonText={pB.buttonText}
            delay={idx / 4}
            key={idx}
            linkedImage={pB.backgroundImage}
            setCurrentImage={setCurrentImage}
            setCurrentMarkers={setCurrentMarkers}
            markers={pB.markers}
            setZoomedMenu={setZoomedMenu}
          />
        ))}
      </div>
    </motion.div>
  );
};
