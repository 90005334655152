import { useSpring, animated } from '@react-spring/web';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { GestureWindowProps } from 'types';
import useWindowDimensions from 'utils/useWindowDimensions';

const DEFAULT_WIDTH = 1240;
const DEFAULT_HEIGHT = 877;

const useGesture = createUseGesture([dragAction, pinchAction]);

export const GestureWindow: React.FC<GestureWindowProps> = ({
  image,
  setGestureWindows,
  plansData,
  idx,
}) => {
  const { height, width } = useWindowDimensions();

  function getMarkerPosition() {
    return {
      //transform the % into absolute value based on the viewport size
      top:
        (height / 100) *
        parseInt(
          plansData
            .flatMap((i) => i.markers)
            .filter((i) => i.title === image)[0]
            .top.replace('%', ''),
        ),
      left:
        (width / 100) *
        parseInt(
          plansData
            .flatMap((i) => i.markers)
            .filter((i) => i.title === image)[0]
            .left.replace('%', ''),
        ),
    };
  }

  const ref = React.useRef<HTMLDivElement>(null);

  const [appearance, setAppearance] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 0.25,
    rotateZ: 0,
  }));

  const DEFAULT_WINDOW_STYLE = {
    ...appearance,
    zIndex: 9999,
  };

  const [isWindowZoomed, setIsWindowZoomed] = useState(false);
  const [isWindowIcon, setIsWindowIcon] = useState(false);
  const [windowStyle, setWindowStyle] = useState(DEFAULT_WINDOW_STYLE);

  useEffect(() => {
    const gestureHandler = (e: Event) => e.preventDefault();
    document.addEventListener('gestureStart', gestureHandler);
    document.addEventListener('gestureChange', gestureHandler);
    document.addEventListener('gestureEnd', gestureHandler);
    return () => {
      document.removeEventListener('gestureStart', gestureHandler);
      document.removeEventListener('gestureChange', gestureHandler);
      document.removeEventListener('gestureEnd', gestureHandler);
    };
  }, []);

  const handleExpandClick = () => {
    setAppearance.start({ scale: 1 });
    setWindowStyle(DEFAULT_WINDOW_STYLE);
    setAppearance.start({ x: 100, y: -30 });
    setIsWindowZoomed(true);
    setIsWindowIcon(false);
  };

  const handleReduceClick = () => {
    setAppearance.start({ scale: 0.25 });
    setIsWindowZoomed(false);
  };

  const handleCloseClick = () => {
    setGestureWindows((prev) => prev.filter((gW) => gW !== image));
  };

  function getIconCoordinates() {
    //Display icons in a grid based on their number
    return {
      top: 750 - Math.floor(idx / 8) * 50,
      left: (idx % 8) * 180,
    };
  }

  const handleIconClick = () => {
    setIsWindowIcon(true);
    handleReduceClick();
    setWindowStyle((windowStyle) => {
      return {
        ...windowStyle,
        height: 180,
        width: 700,
        backgroundImage: 'none',
        backgroundColor: '#FFF',
        top: getIconCoordinates().top,
        left: getIconCoordinates().left,
        opacity: 0.8,
        color: '#324a66',
      };
    });
    setAppearance.start({ x: -100, y: 100 });
  };
  const buttonsStyle: React.CSSProperties = isWindowZoomed
    ? //Buttons position if window is zoomed
      {
        transform: 'scale(0.65)',
        position: 'absolute',
        bottom: 870,
        left: 1070,
      }
    : //Buttons position if window is in icon status
    isWindowIcon
    ? {
        transform: 'scale(2.5)',
        position: 'absolute',
        bottom: 55,
        left: 140,
      }
    : //Buttons position if window is normal (not zoomed nor icon)
      {
        transform: 'scale(2.5)',
        position: 'absolute',
        bottom: 910,
        left: 950,
      };

  const useStyles = createUseStyles({
    card: {
      position: 'absolute',
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
      objectFit: 'cover',
      background: `url('/image/planimetry/${image.toLowerCase()}.png')`,
      backgroundColor: '#334a66',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderRadius: '5px',
      boxShadow: ' 0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
      willChange: 'transform',
      border: '10px solid white',
      cursor: 'grab',
      touchAction: 'none',
      userSelect: 'none',
      webkitUserSelect: 'none',
      // overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: '22px',
      padding: '20px',
      textAlign: 'center',
      color: '#000',
      top: getMarkerPosition().top - 500,
      left: getMarkerPosition().left - 500,
    },
    cursorPointer: { cursor: 'pointer' },
  });

  const classes = useStyles();

  useGesture(
    {
      onPinch: ({
        origin: [originX, originY],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (!ref.current) {
          return;
        }

        if (first) {
          const { width, height, x, y } = ref.current?.getBoundingClientRect();
          const tx = originX - (x + width / 2);
          const ty = originY - (y + height / 2);
          memo = [appearance.x.get(), appearance.y.get(), tx, ty];
        }

        const x = memo[0] - (ms - 1) * memo[2];
        const y = memo[1] - (ms - 1) * memo[3];

        setAppearance.start({
          scale: s,
          rotateZ: Math.round(a / 90) * 90,
          x,
          y,
        });
        return memo;
      },

      onDrag: ({ pinching, cancel, offset: [x, y], down }) => {
        if (pinching) return cancel();
        setAppearance.start({ x, y, immediate: down });
      },
    },
    {
      target: ref,
      drag: { from: () => [appearance.x.get(), appearance.y.get()] },
      pinch: { scaleBounds: { min: 0.25, max: 2 }, rubberband: true },
    },
  );
  return (
    <motion.div
      className={`flex fill center container`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={image}
    >
      <animated.div className={classes.card} ref={ref} style={windowStyle}>
        {isWindowIcon && (
          <div style={{ fontSize: 65, marginLeft: 350 }}>{image}</div>
        )}
        <div className="gestureWindowButtons" style={buttonsStyle}>
          <span onClick={handleIconClick} className={classes.cursorPointer}>
            <img src="/image/ico-win-icon.svg" alt="icon" />
          </span>
          {!isWindowZoomed && (
            <span onClick={handleExpandClick} className={classes.cursorPointer}>
              <img src="/image/ico-win-max.svg" alt="icon" />
            </span>
          )}
          {isWindowZoomed && (
            <span onClick={handleReduceClick} className={classes.cursorPointer}>
              <img src="/image/ico-win-min.svg" alt="icon" />
            </span>
          )}
          <span onClick={handleCloseClick} className={classes.cursorPointer}>
            <img src="/image/ico-win-close.svg" alt="icon" />
          </span>
        </div>
      </animated.div>
    </motion.div>
  );
};
