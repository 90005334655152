import { HomeButton } from 'components/HomeButton';
import React, { Fragment } from 'react';
import { useStyles } from 'styles';
import { BasicPageProps } from 'types';
import { MotionDiv } from 'utils/MotionDiv';

export const Partner: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="partners"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
