import { GallerySlideshow } from 'components/GallerySlideshow';
import { HomeButton } from 'components/HomeButton';
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStyles } from 'styles';
import { BasicPageProps } from 'types';
import { MotionDiv } from 'utils/MotionDiv';

export const Location: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  const classes = useStyles();

  const locationImages = [
    {
      original: '/image/location/location.jpg',
      thumbnail: '/image/location/location.jpg',
    },
    {
      original: '/image/location/location2.jpg',
      thumbnail: '/image/location/location2.jpg',
    },
    {
      original: '/image/location/location3.jpg',
      thumbnail: '/image/location/location3.jpg',
    },
    {
      original: '/image/location/location4.jpg',
      thumbnail: '/image/location/location4.jpg',
    },
    {
      original: '/image/location/location5.jpg',
      thumbnail: '/image/location/location5.jpg',
    },
    {
      original: '/image/location/location6.jpg',
      thumbnail: '/image/location/location6.jpg',
    },
    {
      original: '/image/location/location7.jpg',
      thumbnail: '/image/location/location7.jpg',
    },
    {
      original: '/image/location/location8.jpg',
      thumbnail: '/image/location/location8.jpg',
    },
    {
      original: '/image/location/location9.jpg',
      thumbnail: '/image/location/location9.jpg',
    },
  ];

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col xl={10} className={`offset-md-2 ${classes.galleryContainer}`}>
              <GallerySlideshow images={locationImages} />
            </Col>
          </Row>
        </Container>

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Location"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
