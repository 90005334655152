import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useStyles } from 'styles';

const VIDEO_DATA = {
  webm: {
    src: '/video/video.webm',
    type: 'video/webm',
  },
  mp4: {
    src: '/video/video.mp4',
    type: 'video/mp4',
  },
};

export const IdlePage = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div
      className="cover-container d-flex w-100 h-100 mx-auto flex-column"
      onClick={() => {
        navigate('/');
      }}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classes.background}
          key="video"
          transition={{
            duration: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            className={classes.backgroundObject}
          >
            <source src={VIDEO_DATA.mp4.src} type={VIDEO_DATA.mp4.type} />
            <source src={VIDEO_DATA.webm.src} type={VIDEO_DATA.webm.type} />
          </video>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
