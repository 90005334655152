import { GallerySlideshow } from 'components/GallerySlideshow';
import { HomeButton } from 'components/HomeButton';
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStyles } from 'styles';
import { BasicPageProps } from 'types';
import { MotionDiv } from 'utils/MotionDiv';

export const StylePage: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  const classes = useStyles();

  const styleImages = [
    {
      original: '/image/style/render.jpg',
      thumbnail: '/image/style/render.jpg',
    },
    {
      original: '/image/style/render2.jpg',
      thumbnail: '/image/style/render2.jpg',
    },
    {
      original: '/image/style/render3.jpg',
      thumbnail: '/image/style/render3.jpg',
    },
    {
      original: '/image/style/render4.jpg',
      thumbnail: '/image/style/render4.jpg',
    },
    {
      original: '/image/style/render5.jpg',
      thumbnail: '/image/style/render5.jpg',
    },
    {
      original: '/image/style/render6.jpg',
      thumbnail: '/image/style/render6.jpg',
    },
    {
      original: '/image/style/render7.jpg',
      thumbnail: '/image/style/render7.jpg',
    },
    {
      original: '/image/style/render8.jpg',
      thumbnail: '/image/style/render8.jpg',
    },
    {
      original: '/image/style/render9.jpg',
      thumbnail: '/image/style/render9.jpg',
    },
    {
      original: '/image/style/render10.jpg',
      thumbnail: '/image/style/render10.jpg',
    },
    {
      original: '/image/style/render11.jpg',
      thumbnail: '/image/style/render11.jpg',
    },
    {
      original: '/image/style/render12.jpg',
      thumbnail: '/image/style/render12.jpg',
    },
    {
      original: '/image/style/render13.jpg',
      thumbnail: '/image/style/render13.jpg',
    },
    {
      original: '/image/style/render14.jpg',
      thumbnail: '/image/style/render14.jpg',
    },
    {
      original: '/image/style/render15.jpg',
      thumbnail: '/image/style/render15.jpg',
    },
  ];

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col xl={10} className={`offset-md-2 ${classes.galleryContainer}`}>
              <GallerySlideshow images={styleImages} />
            </Col>
          </Row>
        </Container>
        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="style"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
